<script setup>
import { BellIcon, IdentificationIcon, LinkIcon } from '@heroicons/vue/outline';

const features = [
  {
    name: 'register.profileCustomization',
    description: 'register.profileCustomizationDescription',
    icon: IdentificationIcon,
  },
  {
    name: 'register.socialVerification',
    description: 'register.socialVerificationDescription',
    icon: LinkIcon,
  },
  {
    name: 'register.notificationsOnBids',
    description: 'register.notificationsOnBidsDescription',
    icon: BellIcon,
  },
];
</script>

<template>
  <div class="relative bg-white py-16 dark:bg-black sm:py-24 lg:py-32">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="feature in features" :key="feature.name" class="pt-6">
          <div class="flow-root rounded-lg bg-neutral-50 px-6 pb-8 dark:bg-neutral-800">
            <div class="-mt-6">
              <div>
                <span class="inline-flex items-center justify-center rounded-md bg-emerald-600 p-3 shadow-lg">
                  <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <h3 class="mt-8 text-lg font-medium tracking-tight text-neutral-900 dark:text-neutral-50">
                {{ $t(feature.name) }}
              </h3>
              <p class="mt-5 text-base text-neutral-500 dark:text-neutral-400">{{ $t(feature.description) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
